.bio__container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}

.bio {
  display: flex;
  height: 100vh;
}

.bio__section {
  display: flex;
  height: 100vh;
  width: 100vw;
  /* justify-content: space-around; */
}

.bio__heading {
  display: flex;
  flex-direction: column;
  margin: auto;
  height: 60vh;
  width: 40%;
  align-items: center;
  text-align: center;
  /* justify-content: space-around; */
}

.bio__intro {
  text-align: left;
}
.bio__heading h1 {
  align-items: left;
  font-weight: bold;
  color: #3d4357;
  margin-left: 15px;
}
.bio__heading p {
  font-family: 'Comic Sans';
  font-size: 19px;
  color: #3d4357;
  margin-top: 10px;
  margin-left: 15px;
  /* margin-bottom: 0px; */
}

.bio__heading h6 {
  font-size: 15px;
  color: #3d4357;
  margin-left: 15px;
}

.bio__image {
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-left: 0;
  text-align: left;
  align-items: left;
  width: 100vw;
  height: 55vh;
  border-radius: 10%;
}

/*New changes*/
.bio__data {
  text-align: left;
}

.bio__data h4 {
  align-items: left;
  /* font-weight: bold; */
  color: #3d4357;
}

/* location image gif*/
.bio__location {
  width: 40px;
  height: 40px;
}
.bio__glasses {
  width: 50px;
  height: 50px;
}
.bio__education {
  width: 50px;
  height: 50px;
}
.bio__tool {
  width: 45px;
  height: 45px;
}

.bio__image p {
  font-family: 'Comic Sans';
  font-size: 19px;
  color: #3d4357;
  margin-top: 10px;
}

.bio__image h1 {
  margin-bottom: 20px;
  align-items: left;
  font-weight: bold;
  color: #3d4357;
}

.up {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px;
}

/**/
.bio__name {
  font-family: 'Comic Sans';
  color: #bf1717;
  font-weight: bold;
}

.btn_download__resume {
  padding: 10px;
  font-size: 15px;
  color: #ffffff;
  background: #bf1717;
  border: none;
  border-radius: 10px;
  box-shadow: 0 10px 15px rgba(80, 18, 18, 0.3);
  text-transform: uppercase;
  margin-left: 15px;
}

.bio__resume {
  margin-top: 60px;
  text-align: left;
}

.bio__socials {
  text-align: left;
  align-items: left;
  justify-content: left;
}

.bio__contact {
  margin-top: 50px;
}

.bio__scroll {
  margin-top: 50px;
}
.bio__scroll .fa {
  margin-left: 5px;
}

.btn__scroll {
  font-size: 18px;
  border: none;
  color: #3d4357;
  background: #f9f9f9;
  font-weight: 500;
  border-radius: 20px;
  margin-left: 15px;
}

.bio__scrollup {
  display: flex;
  text-align: right;
  align-items: center;
  margin: 0 auto;
  padding: 10px;
}

.btn__scrollup {
  font-size: 20px;
  border: none;
  padding: 10px;
  align-items: center;
  text-align: center;
  background: #ffffff;
  font-weight: 500;
}

.bio__up {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
}

ul {
  display: flex;
  justify-content: left;
}

ul li {
  list-style: none;
  margin-right: 1em;
}

ul li a {
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 0 10px;
  padding: 6px;
  text-decoration: none;
  box-sizing: border-box;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background: linear-gradient(0deg, #ddd, #fff);
  transition: 0.5s;
}

ul li a:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  text-decoration: none;
}

ul li a .fa {
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(0deg, #fff, #ddd);
  border-radius: 50%;
  line-height: calc(50px - 12px);
  font-size: 18px;
  color: #262626;
  transition: 0.5s;
}
ul li:nth-child(1) a:hover .fa {
  color: #bf1717;
}
ul li:nth-child(2) a:hover .fa {
  color: #3b5998;
}
ul li:last-child a:hover .fa {
  color: #9c45a9;
}

ul li span {
  display: block;
  width: 50px;
  height: 50px;
  text-align: center;
  margin: 0 10px;
  padding: 6px;
  text-decoration: none;
  box-sizing: border-box;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  background: linear-gradient(0deg, #ddd, #fff);
  transition: 0.5s;
  cursor: pointer;
}

ul li span:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  text-decoration: none;
}

ul li span .fa {
  width: 100%;
  height: 100%;
  display: block;
  background: linear-gradient(0deg, #fff, #ddd);
  border-radius: 50%;
  line-height: calc(50px - 12px);
  font-size: 18px;
  color: #262626;
  transition: 0.5s;
}
ul li:nth-child(1) span:hover .fa {
  color: #bf1717;
}
ul li:nth-child(2) span:hover .fa {
  color: #3b5998;
}
ul li:last-child span:hover .fa {
  color: #9c45a9;
}

span:hover {
  text-decoration: none;
  transition: all 0.5s ease-out;
  transform: translateY(5px);
  color: #000000;
}

.btn {
  box-sizing: border-box;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  transition: all 0.5s;
}

/*Media queries for responsiveness*/

@media only screen and (max-width: 840px) {
  .bio__image {
    display: none;
  }

  .bio__heading {
    width: 40vh;
  }
}

@media only screen and (min-width: 600px) {
  .bio__image {
    width: 35vw;
  }
}

@media only screen and (min-width: 768px) {
  .bio__image {
    width: 40vw;
  }
}
