* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
}

.app {
  height: 100vh;
  background-color: #f9f9f9;
  background-size: cover;
}

::-webkit-scrollbar {
  display: none;
}
