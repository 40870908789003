.projects__heading {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.projects__heading h2 {
  text-transform: uppercase;
  margin-top: 20px;
  font-weight: 600;
  font-size: 40px;
  color: #3d4357;
}

.projects {
  margin-top: 10px;
  margin: 10px;
  padding: 10px;
}

.project__card {
  margin-top: 20px;
  padding: 20px;
  transition: 0.2s linear, transform 0.5s;
  box-sizing: border-box;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.3);
  font-family: 'Comic Sans';
  font-weight: normal;
}

.project__card:hover {
  transform: translateY(10px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.project__card a {
  text-decoration: none;
  animation: none;
  transition: none;
  transform: none;
}
